/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
.titolo{
    font-family: 'Orbitron', sans-serif;
}
.text-siras{
    color: #174252 !important;
    font-size: 1rem;
    font-weight: 100px !important;
}
.lista{
    list-style: none;
    line-height: 35px;
}

.font-siras{
    font-size: 18px !important;
    font-weight: 100px !important;
    font-family: 'Orbitron', sans-serif !important;
}
.bordo-3d{
    border-bottom: 5px solid #1f4c79;
    border-left: 5px solid #1f4c79;
   
}

.m-base{
    padding-left: 15%;
    padding-right:  15%;
}

.bg-warning{
    background-image: linear-gradient(to right, rgb(255, 208, 0) , white);
    border-right: 2px solid rgb(255, 208, 0)  ;
}

body{
    background-image: url("/assets/img/siras-oleodinamica-pavia-3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #040e18;
}
.radius-min{
    border-radius: 10px;
}
.radius{
    border: 2px solid;
  border-radius: 15px;
  color: #ef9e10;
}
.total-radius{
    border-radius: 200px;
}
.f-large{
    font-size: 2vh;
    line-height: 3vh;
}
.mx-large{
    margin-left: 10%;
    margin-right: 10%;
}
.text-siras{
    color:#12304e ;
}

.bg-siras{
    background-color: #1f4c79;
}
.bg-orange{
    background-color: #ef9e10 !important;
}
.bg-y{
    background-color: #efc610 !important;
}

.bg-darkred{
    background-image: linear-gradient(to right, rgb(255, 0, 0) , white);
    border-right: 2px solid rgb(255, 0, 0)  ;
}


.bg-pneumatica{
    background-image: linear-gradient(to right, rgb(34, 124, 42) , white);
    border-right: 2px solid  rgb(34, 124, 42) ;
}

.brd-pneumatica{
    border-color:rgb(8, 116, 31);
}
.bg-flessibili{
    background-image: linear-gradient(to right, rgb(0, 0, 0) , white);
    border-right: 2px solid  rgb(0, 0, 0) ;
}
.brd-flessibili{
    border-color:rgb(32, 32, 32);
}

.bg-meccanica{
    background-image: linear-gradient(to right, rgb(148, 216, 255) , white);
    border-right: 2px solid  rgb(148, 216, 255) ;
}
.brd-meccanica{
    border-color:rgb(151, 212, 223);
}
.bg-blu{
    background-image: linear-gradient(to right, rgb(13, 0, 192) , white);
    border-right: 2px solid  rgb(13, 0, 192) ;
}
.brd-blu{
    border-color:rgb(11, 1, 150);
}
.font-siras{
    font-family: 'Play', sans-serif;
    font-size: 3vh;
    text-transform: uppercase;
}
.box{
    min-height: 100px;
    max-height: 100px;
}
.m-para{
   
    padding-top: 15%;
}

.h-para{
    min-height: 60%;
}


.material-icons-md-48 { font-size: 8vh; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
